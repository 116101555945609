import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-white py-4 shadow-md border-t text-center" style={{ borderColor: '#6145E1' }}>
      <div className="container mx-auto px-4">
        <h3 className="text-xl font-bold text-purple-600">COPY PASTE FONT GENERATOR</h3>
        <p className="text-gray-600 mb-4">Transform your text into art with our Fancy Font Generator!</p>
        <div className="flex justify-center space-x-4 mb-4">
          <Link to="/privacy-policy" className="hover:underline text-purple-600">Privacy Policy</Link>
          <Link to="/terms-conditions" className="hover:underline text-purple-600">Terms and Conditions</Link>
        </div>
        <p className="text-purple-600 mb-2">&copy; 2024 - All rights reserved</p>
        <p className="text-purple-600">Design and Developed by AppJingle Services</p>
      </div>
    </footer>
  );
};

export default Footer;
