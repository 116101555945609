const fontsMap = {
  'Fontagram': '•´¯`•. *✶ 🎀 {text} 🎀 ✶* .•´¯`•',
  'Fontify': '✿༺{text}༻✿ ',
  'InstaTexta': 'ಠ_ಠ {text} ',
  'FontLuxe': '{text} ',
  'InstaStyler': '🌸💋 {text} 💋🌸 ',
  'InstaType': 'ミ💝 {text} 💝彡 ',
  'InstaFontopia': '(◍•ᴗ•◍) 💖  {text}  💖彡 (◍•ᴗ•◍)',
  'FontFlair': '💖´ *•.¸♥¸.•** {text}  **•.¸♥¸.•*´💖 ',
  'InstaScript': ' ──╦̵̵̿╤︻一═デ︻{text}︻デ═一︻╦̵̵̿╤──',
  'FontFusion': '   ◦•●◉✿ {text} ✿◉●•◦ ',
  'FontVibe': '♥ {text} ♥ ',
  'InstaChicFonts': '{text}',
  'InstaPixel': '╰⭐🌟 {text} 🌟⭐╮',
  'FontFiesta': '•*¨*•.¸¸☆*･ﾟ {text} ﾟ･*☆¸¸.•*¨*•',
  'InstaCharm': '♪¸¸.•*¨*•.{text}.•*¨*•.¸¸♪',
  'FontFable': '🐧😝  {text} 🎀💗',
  'InstaFontology': '* . °•★|•°∵ {text} ∵°•|☆•° . *',
  'FontMosaic': '▀▄▀▄▀▄{text}▀▄▀▄▀▄',
  'InstaQuill': '•*¨*•.¸¸☆*･ﾟ ⁭ {text} ﾟ･*☆¸¸.•*¨*•',
  'FontSprinkle': '·.★·.·´¯`·.·★ {text} ★·.·´¯`·.·★.·',
  'FontMingle': '★¸.•☆•.¸★ {text}★⡀.•☆•.★',
  'InstaEmbellish': '✺✳ ┅{text} ┅ ✳✺',
  'FontFlutter': '◦❧◦°˚°◦.¸¸◦°´❤*•.¸♥ {text} ♥¸.•*❤´°◦¸¸.◦°˚°◦☙◦',
  'Magical Celestial Font': '✩☼☾☁  {text}  ☁☾☼✩',
  'Starlit Dreams': '✩₊˚.⋆☾⋆⁺₊✧{text}✧⁺₊☾⋆₊˚.⋆✩',
  'Celestial Harmony': '👽☆  {text}  ☠🔥',
  'Moonbeam Magic': '✩₊⁺⋆☾⋆⁺₊✧{text}✧⁺₊☾⋆⁺⋆₊✩',
  'Mystic Melody': '⋆｡°✩✮⋆˙*ੈ✩‧₊˚{text}',
  'Cosmic Wonder': '๋⚝🪐⚝👽⚝💫⚝{text}⚝💫⚝👽⚝🪐⚝',
  'Twilight Magic': '☆.´ `. ☽¸.☆, {text} ,☆.¸☽ .` ´.☆',
  'Divine Light': 'ཐི⋆༒︎⋆ཋྀ{text}ཐི⋆༒︎⋆ཋྀ ',
  'Moonlit Melody': '¸.·✮·.¸¸.·¯⍣✮ {text} ✮⍣¯·.¸¸.·✮·.¸',
  'Mystic Mirage': '𓂀 {text} 𓂀',
  'Celestial Chime': '(𓁹 𓁹) {text}(𓁹 𓁹)',
  'Mystic Moonlight': '☠︎︎༒︎🕸 {text}🕸༒︎☠︎︎',
  'Celestial Citadel': '☆꧁✩◦°˚°◦. {text} .◦°˚°◦✩꧂☆',
  'DreamType': '⛧☾༺༒︎༻☽⛧{text}⛧☾༺༒︎༻☽⛧',
  'MagicalScript': '◟(👁 ͜ʖ👁)◞{text}👹',
  'MoonlitLetters': '⋆☾✩☽⋆ᘛᗽ {text} ᘀᘗ⋆☾✩☽⋆',
  'MysticSans': '🎃👻🦇💀🧡{text}🧡💀🦇👻🎃',
  'CelestialScript': '🕷⛥🕸✮{text}✮🕸⛥🕷 ',
  'StarlightSans': '˚˖𓍢ִ໋ 🦇 ✧˚.🔮⋆ {text} ⋆🔮.˚✧🦇𓍢ִ໋˖˚',
  'FloralCaps': '✿♥‿♥✿ {text} ✿♥‿♥✿',
  'Blossom': '  🌷🎀 {text}🎀🌷 ',
  'Daisy Delight': '✴:✿ {text} ✿ :✴',
  'Rosewood Font': '*＊✿❀{text}❀✿＊*',
  'Daffodil Doodles': '🎂 🎀 {text} 🎀 🎂',
  'Sunflower Script': '(˶◕‿◕){text} (˶◕‿◕)',
  'Tulip Type': 'ཫ꙳🌺{text}🌺꙳ཀ',
  'Love Lettering': '💕🌸 {text}💕🌸',
  'Graceful Script': '❀💋❀ {text} ❀💋❀',
  'Vow Script': '✿ ꗥ～ꗥ✿{text}✿ ꗥ～ꗥ✿ ',
  'Love Serif': '≧❀‿❀≦♥{text}♥≧❀‿❀≦♥',
  'Springtime': '˚ ༘♡ ⋆｡˚ 🌸{text}🌸˚｡⋆♡ ༘˚',
  'Rendition Serif': ' 🌻🎀 {text} 🎀 🌻',
  'DigiStamps': '🌸💕{text} 💕🌸',
  'Sweet Flower': '✷🍀 🎀  {text} 🎀 🍀✷',
  'Petal Prose': '❀💋❀ {text} ❀💋❀',
  'Daisy Dream': '❊✿❀{text}✿❀❊',
  'Blooming Beauty': ' ❁ ✿ ❀ {text} ❀ ✿ ❁',
  'Poppy Pages': '(❀❛ ֊ ❛„)♡ {text} ♡(❛„ ֊❀❛)',
  'Tulip serif': '˚.⋆ꕤ°˖✿˚⋆❀{text} ❀⋆˚✿˖°ꕤ⋆.˚',
  'Hibiscus Handwriting': '❀｡ ₊°༺  {text}  ༻°₊ ｡❀',
  'Lily Lattice': '꧁✿˙ {text}˙✿꧂',
  'Violet Vibes': '⋆｡‧˚ʚ🌷ɞ˚‧｡⋆ {text} ⋆｡‧˚ʚ🌷ɞ˚‧｡⋆',
  'Floral Fancy': 'ꕤ꧁ღ⊱♥ {text} ♥⊱ღ꧂ꕤ',
  'Bluebell Brush': "('•×•)☆.•° ✿ °•. {text} .•°✿°•.☆(•×•')",
  'Iris Italics': '⋆｡‧˚ʚ ❀ ɞ˚‧｡⋆ {text} ⋆｡‧˚ʚ ❀ ɞ˚‧｡⋆',

};

const fontPlaceholders = {
  'Fontagram': 'Sample Text',
  'Fontify': 'Example Text',
  'InstaTexta': 'Demo Text',
  'FontLuxe': 'Placeholder Text',
  'InstaStyler': 'Sample Placeholder',
  'InstaType': 'Sample Text',
  'InstaFontopia': 'Sample Text',
  'FontFlair': 'Sample Text',
  'InstaScript': 'Sample Text',
  'FontFusion': 'Sample Text',
  'FontVibe': 'Sample Text',
  'InstaChicFonts': 'Sample Text',
  'InstaPixel': 'Sample Text',
  'FontFiesta': 'Sample Text',
  'InstaCharm': 'Sample Text',
  'FontFable': 'Sample Text',
  'InstaFontology': 'Sample Text',
  'FontMosaic': 'Sample Text',
  'InstaQuill': 'Sample Text',
  'FontSprinkle': 'Sample Text',
  'FontMingle': 'Sample Text',
  'InstaEmbellish': 'Sample Text',
  'FontFlutter': 'Sample Text',
  'Magical Celestial Font': 'Sample Text',
  'Starlit Dreams': 'Sample Text',
  'Celestial Harmony': 'Sample Text',
  'Moonbeam Magic': 'Sample Text',
  'Mystic Melody': 'Sample Text',
  'Cosmic Wonder': 'Sample Text',
  'Twilight Magic': 'Sample Text',
  'Divine Light': 'Sample Text',
  'Moonlit Melody': 'Sample Text',
  'Mystic Mirage': 'Sample Text',
  'Celestial Chime': 'Sample Text',
  'Mystic Moonlight': 'Sample Text',
  'Celestial Citadel': 'Sample Text',
  'DreamType': 'Sample Text',
  'MagicalScript': 'Sample Text',
  'MoonlitLetters': 'Sample Text',
  'MysticSans': 'Sample Text',
  'CelestialScript': 'Sample Text',
  'StarlightSans': 'Sample Text',
  'FloralCaps': 'Sample Text',
  'Blossom': 'Sample Text',
  'Daisy Delight': 'Sample Text',
  'Rosewood Font': 'Sample Text',
  'Daffodil Doodles': 'Sample Text',
  'Sunflower Script': 'Sample Text',
  'Tulip Type': 'Sample Text',
  'Love Lettering': 'Sample Text',
  'Graceful Script': 'Sample Text',
  'Vow Script': 'Sample Text',
  'Love Serif': 'Sample Text',
  'Springtime': 'Sample Text',
  'Rendition Serif': 'Sample Text',
  'DigiStamps': 'Sample Text',
  'Sweet Flower': 'Sample Text',
  'Petal Prose': 'Sample Text',
  'Daisy Dream': 'Sample Text',
  'Blooming Beauty': 'Sample Text',
  'Poppy Pages': 'Sample Text',
  'Tulip serif': 'Sample Text',
  'Hibiscus Handwriting': 'Sample Text',
  'Lily Lattice': 'Sample Text',
  'Violet Vibes': 'Sample Text',
  'Floral Fancy': 'Sample Text',
  'Bluebell Brush': 'Sample Text',
  'Iris Italics': 'Sample Text',

};

const fontCharMaps = {
  'Fontagram': {
    'A': '𝔄', 'B': '𝔅', 'C': 'ℭ', 'D': '𝔇', 'E': '𝔈', 'F': '𝔉', 'G': '𝔊',
    'H': 'ℌ', 'I': 'ℑ', 'J': '𝔍', 'K': '𝔎', 'L': '𝔏', 'M': '𝔐', 'N': '𝔑',
    'O': '𝔒', 'P': '𝔓', 'Q': '𝔔', 'R': 'ℜ', 'S': '𝔖', 'T': '𝔗', 'U': '𝔘',
    'V': '𝔙', 'W': '𝔚', 'X': '𝔛', 'Y': '𝔜', 'Z': 'ℨ', 'a': '𝔞', 'b': '𝔟',
    'c': '𝔠', 'd': '𝔡', 'e': '𝔢', 'f': '𝔣', 'g': '𝔤', 'h': '𝔥', 'i': '𝔦',
    'j': '𝔧', 'k': '𝔨', 'l': '𝔩', 'm': '𝔪', 'n': '𝔫', 'o': '𝔬', 'p': '𝔭',
    'q': '𝔮', 'r': '𝔯', 's': '𝔰', 't': '𝔱', 'u': '𝔲', 'v': '𝔳', 'w': '𝔴',
    'x': '𝔵', 'y': '𝔶', 'z': '𝔷'
  },
  'Fontify': {
    'A': 'Ɨ', 'B': 'Ɓ', 'C': 'Ƈ', 'D': 'Ɗ', 'E': 'Ǝ', 'F': 'Ƒ', 'G': 'Ɠ',
    'H': 'ƕ', 'I': 'Ɨ', 'J': 'ǰ', 'K': 'Ƙ', 'L': 'Ƚ', 'M': 'M', 'N': 'Ň',
    'O': 'Ǿ', 'P': 'P', 'Q': 'Q', 'R': 'Ř', 'S': 'Ş', 'T': 'Ŧ', 'U': 'Ǔ',
    'V': 'V', 'W': 'Ɯ', 'X': 'X', 'Y': 'Ƴ', 'Z': 'Ź', 'a': 'ά', 'b': 'в',
    'c': 'ć', 'd': 'đ', 'e': 'έ', 'f': 'ƒ', 'g': 'ġ', 'h': 'ħ', 'i': 'ί',
    'j': 'ĵ', 'k': 'ķ', 'l': 'ļ', 'm': 'м', 'n': 'ή', 'o': 'ő', 'p': 'ρ',
    'q': 'q', 'r': 'ŕ', 's': 'ş', 't': 'ţ', 'u': 'υ', 'v': 'v', 'w': 'ώ',
    'x': 'χ', 'y': 'ŷ', 'z': 'ż'
  },
  'InstaTexta': {
    'A': 'α', 'B': 'в', 'C': '¢', 'D': '∂', 'E': 'є', 'F': 'ƒ', 'G': 'g',
    'H': 'н', 'I': 'ι', 'J': 'נ', 'K': 'к', 'L': 'ℓ', 'M': 'м', 'N': 'η',
    'O': 'σ', 'P': 'ρ', 'Q': 'q', 'R': 'я', 'S': 'ѕ', 'T': 'т', 'U': 'υ',
    'V': 'ν', 'W': 'ω', 'X': 'χ', 'Y': 'у', 'Z': 'z', 'a': 'α', 'b': 'в',
    'c': '¢', 'd': '∂', 'e': 'є', 'f': 'ƒ', 'g': 'g', 'h': 'н', 'i': 'ι',
    'j': 'נ', 'k': 'к', 'l': 'ℓ', 'm': 'м', 'n': 'η', 'o': 'σ', 'p': 'ρ',
    'q': 'q', 'r': 'я', 's': 'ѕ', 't': 'т', 'u': 'υ', 'v': 'ν', 'w': 'ω',
    'x': 'χ', 'y': 'у', 'z': 'z'
  },
  'FontLuxe': {
    'A': 'ℑ', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G',
    'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N',
    'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U',
    'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z', 'a': 'a', 'b': 'b',
    'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i',
    'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p',
    'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w',
    'x': 'x', 'y': 'y', 'z': 'z'
  },
  'InstaStyler': {
    'A': '𝐀', 'B': '𝐁', 'C': '𝐂', 'D': '𝐃', 'E': '𝐄', 'F': '𝐅', 'G': '𝐆',
    'H': '𝐇', 'I': '𝐈', 'J': '𝐉', 'K': '𝐊', 'L': '𝐋', 'M': '𝐌', 'N': '𝐍',
    'O': '𝐎', 'P': '𝐏', 'Q': '𝐐', 'R': '𝐑', 'S': '𝐒', 'T': '𝐓', 'U': '𝐔',
    'V': '𝐕', 'W': '𝐖', 'X': '𝐗', 'Y': '𝐘', 'Z': '𝐙', 'a': '𝐚', 'b': '𝐛',
    'c': '𝐜', 'd': '𝐝', 'e': '𝐞', 'f': '𝐟', 'g': '𝐠', 'h': '𝐡', 'i': '𝐢',
    'j': '𝐣', 'k': '𝐤', 'l': '𝐥', 'm': '𝐦', 'n': '𝐧', 'o': '𝐨', 'p': '𝐩',
    'q': '𝐪', 'r': '𝐫', 's': '𝐬', 't': '𝐭', 'u': '𝐮', 'v': '𝐯', 'w': '𝐰',
    'x': '𝐱', 'y': '𝐲', 'z': '𝐳'
  },

  'InstaType': {
    'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z'
  },

  'InstaFontopia': {
    'A': 'A͙', 'B': 'B͙', 'C': 'C͙', 'D': 'D͙', 'E': 'E͙', 'F': 'F͙', 'G': 'G͙', 'H': 'H͙', 'I': 'I͙', 'J': 'J͙', 'K': 'K͙', 'L': 'L͙', 'M': 'M͙', 'N': 'N͙', 'O': 'O͙', 'P': 'P͙', 'Q': 'Q͙', 'R': 'R͙', 'S': 'S͙', 'T': 'T͙', 'U': 'U͙', 'V': 'V͙', 'W': 'W͙', 'X': 'X͙', 'Y': 'Y͙', 'Z': 'Z͙',
    'a': 'a͙', 'b': 'b͙', 'c': 'c͙', 'd': 'd͙', 'e': 'e͙', 'f': 'f͙', 'g': 'g͙', 'h': 'h͙', 'i': 'i͙', 'j': 'j͙', 'k': 'k͙', 'l': 'l͙', 'm': 'm͙', 'n': 'n͙', 'o': 'o͙', 'p': 'p͙', 'q': 'q͙', 'r': 'r͙', 's': 's͙', 't': 't͙', 'u': 'u͙', 'v': 'v͙', 'w': 'w͙', 'x': 'x͙', 'y': 'y͙', 'z': 'z͙'
  },

  'FontFlair': {
    'A': '𝔸', 'B': '𝔹', 'C': 'ℂ', 'D': '𝔻', 'E': '𝔼', 'F': '𝔽', 'G': '𝔾', 'H': 'ℍ', 'I': '𝕀', 'J': '𝕁', 'K': '𝕂', 'L': '𝕃', 'M': '𝕄', 'N': 'ℕ', 'O': '𝕆', 'P': 'ℙ', 'Q': 'ℚ', 'R': 'ℝ', 'S': '𝕊', 'T': '𝕋', 'U': '𝕌', 'V': '𝕍', 'W': '𝕎', 'X': '𝕏', 'Y': '𝕐', 'Z': 'ℤ',
    'a': '𝕒', 'b': '𝕓', 'c': '𝕔', 'd': '𝕕', 'e': '𝕖', 'f': '𝕗', 'g': '𝕘', 'h': '𝕙', 'i': '𝕚', 'j': '𝕛', 'k': '𝕜', 'l': '𝕝', 'm': '𝕞', 'n': '𝕟', 'o': '𝕠', 'p': '𝕡', 'q': '𝕢', 'r': '𝕣', 's': '𝕤', 't': '𝕥', 'u': '𝕦', 'v': '𝕧', 'w': '𝕨', 'x': '𝕩', 'y': '𝕪', 'z': '𝕫'
  },

  'InstaScript': {
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚', 'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡', 'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨', 'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴', 'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻', 'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂', 'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇'
  },

  'FontFusion': {
    'A': 'Á', 'B': 'B', 'C': 'Ç', 'D': 'D', 'E': 'Ê', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'Ï', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'Ó', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'Ú', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Ý', 'Z': 'Z',
    'a': 'á', 'b': 'b', 'c': 'ç', 'd': 'd', 'e': 'ê', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'ï', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'ó', 'p': 'þ', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'ú', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'ý', 'z': 'z'
  },


  'FontVibe': {
    'A': '🅰', 'B': '🅱', 'C': '🅲', 'D': '🅳', 'E': '🅴', 'F': '🅵', 'G': '🅶', 'H': '🅷', 'I': '🅸', 'J': '🅹', 'K': '🅺', 'L': '🅻', 'M': '🅼', 'N': '🅽', 'O': '🅾', 'P': '🅿', 'Q': '🆀', 'R': '🆁', 'S': '🆂', 'T': '🆃', 'U': '🆄', 'V': '🆅', 'W': '🆆', 'X': '🆇', 'Y': '🆈', 'Z': '🆉',
    'a': '🅰', 'b': '🅱', 'c': '🅲', 'd': '🅳', 'e': '🅴', 'f': '🅵', 'g': '🅶', 'h': '🅷', 'i': '🅸', 'j': '🅹', 'k': '🅺', 'l': '🅻', 'm': '🅼', 'n': '🅽', 'o': '🅾', 'p': '🅿', 'q': '🆀', 'r': '🆁', 's': '🆂', 't': '🆃', 'u': '🆄', 'v': '🆅', 'w': '🆆', 'x': '🆇', 'y': '🆈', 'z': '🆉'
  },

  'InstaChicFonts': {
    'A': '〔A〕', 'B': '〔B〕', 'C': '〔C〕', 'D': '〔D〕', 'E': '〔E〕', 'F': '〔F〕', 'G': '〔G〕', 'H': '〔H〕', 'I': '〔I〕', 'J': '〔J〕', 'K': '〔K〕', 'L': '〔L〕', 'M': '〔M〕', 'N': '〔N〕', 'O': '〔O〕', 'P': '〔P〕', 'Q': '〔Q〕', 'R': '〔R〕', 'S': '〔S〕', 'T': '〔T〕', 'U': '〔U〕', 'V': '〔V〕', 'W': '〔W〕', 'X': '〔X〕', 'Y': '〔Y〕', 'Z': '〔Z〕',
    'a': '〔a〕', 'b': '〔b〕', 'c': '〔c〕', 'd': '〔d〕', 'e': '〔e〕', 'f': '〔f〕', 'g': '〔g〕', 'h': '〔h〕', 'i': '〔i〕', 'j': '〔j〕', 'k': '〔k〕', 'l': '〔l〕', 'm': '〔m〕', 'n': '〔n〕', 'o': '〔o〕', 'p': '〔p〕', 'q': '〔q〕', 'r': '〔r〕', 's': '〔s〕', 't': '〔t〕', 'u': '〔u〕', 'v': '〔v〕', 'w': '〔w〕', 'x': '〔x〕', 'y': '〔y〕', 'z': '〔z〕'
  },

  'InstaPixel': {
    'A': 'Å⫶', 'B': 'B̊⫶', 'C': 'C̊⫶', 'D': 'D̊⫶', 'E': 'E̊⫶', 'F': 'F̊⫶', 'G': 'G̊⫶', 'H': 'H̊⫶', 'I': 'I̊⫶', 'J': 'J̊⫶', 'K': 'K̊⫶', 'L': 'L̊⫶', 'M': 'M̊⫶', 'N': 'N̊⫶', 'O': 'O̊⫶', 'P': 'P̊⫶', 'Q': 'Q̊⫶', 'R': 'R̊⫶', 'S': 'S̊⫶', 'T': 'T̊⫶', 'U': 'Ů⫶', 'V': 'V̊⫶', 'W': 'W̊⫶', 'X': 'X̊⫶', 'Y': 'Y̊⫶', 'Z': 'Z̊⫶',
    'a': 'å⫶', 'b': 'b̊⫶', 'c': 'c̊⫶', 'd': 'd̊⫶', 'e': 'e̊⫶', 'f': 'f̊⫶', 'g': 'g̊⫶', 'h': 'h̊⫶', 'i': 'i̊⫶', 'j': 'j̊⫶', 'k': 'k̊⫶', 'l': 'l̊⫶', 'm': 'm̊⫶', 'n': 'n̊⫶', 'o': 'o̊⫶', 'p': 'p̊⫶', 'q': 'q̊⫶', 'r': 'r̊⫶', 's': 's̊⫶', 't': 't̊⫶', 'u': 'ů⫶', 'v': 'v̊⫶', 'w': 'ẘ⫶', 'x': 'x̊⫶', 'y': 'ẙ⫶', 'z': 'z̊⫶'
  },

  'FontFiesta': {
    'A': 'A⨳', 'B': 'B⨳', 'C': 'C⨳', 'D': 'D⨳', 'E': 'E⨳', 'F': 'F⨳', 'G': 'G⨳', 'H': 'H⨳', 'I': 'I⨳', 'J': 'J⨳', 'K': 'K⨳', 'L': 'L⨳', 'M': 'M⨳', 'N': 'N⨳', 'O': 'O⨳', 'P': 'P⨳', 'Q': 'Q⨳', 'R': 'R⨳', 'S': 'S⨳', 'T': 'T⨳', 'U': 'U⨳', 'V': 'V⨳', 'W': 'W⨳', 'X': 'X⨳', 'Y': 'Y⨳', 'Z': 'Z⨳',
    'a': 'a⨳', 'b': 'b⨳', 'c': 'c⨳', 'd': 'd⨳', 'e': 'e⨳', 'f': 'f⨳', 'g': 'g⨳', 'h': 'h⨳', 'i': 'i⨳', 'j': 'j⨳', 'k': 'k⨳', 'l': 'l⨳', 'm': 'm⨳', 'n': 'n⨳', 'o': 'o⨳', 'p': 'p⨳', 'q': 'q⨳', 'r': 'r⨳', 's': 's⨳', 't': 't⨳', 'u': 'u⨳', 'v': 'v⨳', 'w': 'w⨳', 'x': 'x⨳', 'y': 'y⨳', 'z': 'z⨳'
  },

  'InstaCharm': {
    'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔', 'F': '𝓕', 'G': '𝓖', 'H': '𝓗', 'I': '𝓘', 'J': '𝓙', 'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝', 'O': '𝓞', 'P': '𝓟', 'Q': '𝓠', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣', 'U': '𝓤', 'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩',
    'a': '𝓪', 'b': '𝓫', 'c': '𝓬', 'd': '𝓭', 'e': '𝓮', 'f': '𝓯', 'g': '𝓰', 'h': '𝓱', 'i': '𝓲', 'j': '𝓳', 'k': '𝓴', 'l': '𝓵', 'm': '𝓶', 'n': '𝓷', 'o': '𝓸', 'p': '𝓹', 'q': '𝓺', 'r': '𝓻', 's': '𝓼', 't': '𝓽', 'u': '𝓾', 'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝔂', 'z': '𝔃'
  },

  'FontFable': {
    'A': '𝒜', 'B': 'Ƀ', 'C': 'ℂ', 'D': 'Ꭰ', 'E': 'ᗴ', 'F': 'ℱ', 'G': '𝒢', 'H': 'ℋ', 'I': 'Ꭵ', 'J': '𝒥', 'K': 'Ḱ', 'L': 'Ꮮ', 'M': 'ℳ', 'N': 'И', 'O': 'Ⓞ', 'P': 'Ƥ', 'Q': 'ℚ', 'R': 'я', 'S': '𝒮', 'T': '𝒯', 'U': 'น', 'V': 'Ⓥ', 'W': 'ฬ', 'X': 'Ꮖ', 'Y': 'Ɏ', 'Z': '乙',
    'a': 'ค', 'b': 'ც', 'c': '𝒸', 'd': 'ძ', 'e': 'ᗴ', 'f': 'ғ', 'g': 'ɠ', 'h': 'ん', 'i': 'เ', 'j': 'ן', 'k': 'к', 'l': 'ℓ', 'm': '๓', 'n': 'ภ', 'o': 'ᥫ', 'p': 'թ', 'q': 'ợ', 'r': 'я', 's': 'ร', 't': 'Շ', 'u': 'น', 'v': 'ง', 'w': 'ฬ', 'x': 'א', 'y': 'ყ', 'z': 'չ'
  },

  'InstaFontology': {
    'A': '∀', 'B': '𐐒', 'C': 'Ↄ', 'D': '◖', 'E': 'Ǝ', 'F': 'Ⅎ', 'G': '⅁', 'H': 'H', 'I': 'I', 'J': 'ſ', 'K': '⋊', 'L': '⅃', 'M': 'W', 'N': 'ᴎ', 'O': 'O', 'P': 'Ԁ', 'Q': 'Ό', 'R': 'ᴚ', 'S': 'S', 'T': '⊥', 'U': '∩', 'V': 'Λ', 'W': 'M', 'X': 'X', 'Y': '⅄', 'Z': 'Z',
    'a': 'ɐ', 'b': 'q', 'c': 'ɔ', 'd': 'p', 'e': 'ǝ', 'f': 'ɟ', 'g': 'ƃ', 'h': 'ɥ', 'i': 'ᴉ', 'j': 'ɾ', 'k': 'ʞ', 'l': 'ʃ', 'm': 'ɯ', 'n': 'u', 'o': 'o', 'p': 'd', 'q': 'b', 'r': 'ɹ', 's': 's', 't': 'ʇ', 'u': 'n', 'v': 'ʌ', 'w': 'ʍ', 'x': 'x', 'y': 'ʎ', 'z': 'z'
  },

  'FontMosaic': {
    'A': '🅰', 'B': '🅱', 'C': '🅲', 'D': '🅳', 'E': '🅴', 'F': '🅵', 'G': '🅶', 'H': '🅷', 'I': '🅸', 'J': '🅹', 'K': '🅺', 'L': '🅻', 'M': '🅼', 'N': '🅽', 'O': '🅾', 'P': '🅿', 'Q': '🆀', 'R': '🆁', 'S': '🆂', 'T': '🆃', 'U': '🆄', 'V': '🆅', 'W': '🆆', 'X': '🆇', 'Y': '🆈', 'Z': '🆉',
    'a': '🅰', 'b': '🅱', 'c': '🅲', 'd': '🅳', 'e': '🅴', 'f': '🅵', 'g': '🅶', 'h': '🅷', 'i': '🅸', 'j': '🅹', 'k': '🅺', 'l': '🅻', 'm': '🅼', 'n': '🅽', 'o': '🅾', 'p': '🅿', 'q': '🆀', 'r': '🆁', 's': '🆂', 't': '🆃', 'u': '🆄', 'v': '🆅', 'w': '🆆', 'x': '🆇', 'y': '🆈', 'z': '🆉'
  },

  'InstaQuill': {
    'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z'
  },

  'FontSprinkle': {
    'A': '🄰', 'B': '🄱', 'C': '🄲', 'D': '🄳', 'E': '🄴', 'F': '🄵', 'G': '🄶', 'H': '🄷', 'I': '🄸', 'J': '🄹', 'K': '🄺', 'L': '🄻', 'M': '🄼', 'N': '🄽', 'O': '🄾', 'P': '🄿', 'Q': '🅀', 'R': '🅁', 'S': '🅂', 'T': '🅃', 'U': '🅄', 'V': '🅅', 'W': '🅆', 'X': '🅇', 'Y': '🅈', 'Z': '🅉',
    'a': '🄰', 'b': '🄱', 'c': '🄲', 'd': '🄳', 'e': '🄴', 'f': '🄵', 'g': '🄶', 'h': '🄷', 'i': '🄸', 'j': '🄹', 'k': '🄺', 'l': '🄻', 'm': '🄼', 'n': '🄽', 'o': '🄾', 'p': '🄿', 'q': '🅀', 'r': '🅁', 's': '🅂', 't': '🅃', 'u': '🅄', 'v': '🅅', 'w': '🅆', 'x': '🅇', 'y': '🅈', 'z': '🅉'
  },

  'FontMingle': {
    'A': '🅐', 'B': '🅑', 'C': '🅒', 'D': '🅓', 'E': '🅔', 'F': '🅕', 'G': '🅖', 'H': '🅗', 'I': '🅘', 'J': '🅙', 'K': '🅚', 'L': '🅛', 'M': '🅜', 'N': '🅝', 'O': '🅞', 'P': '🅟', 'Q': '🅠', 'R': '🅡', 'S': '🅢', 'T': '🅣', 'U': '🅤', 'V': '🅥', 'W': '🅦', 'X': '🅧', 'Y': '🅨', 'Z': '🅩',
    'a': '🅐', 'b': '🅑', 'c': '🅒', 'd': '🅓', 'e': '🅔', 'f': '🅕', 'g': '🅖', 'h': '🅗', 'i': '🅘', 'j': '🅙', 'k': '🅚', 'l': '🅛', 'm': '🅜', 'n': '🅝', 'o': '🅞', 'p': '🅟', 'q': '🅠', 'r': '🅡', 's': '🅢', 't': '🅣', 'u': '🅤', 'v': '🅥', 'w': '🅦', 'x': '🅧', 'y': '🅨', 'z': '🅩'
  },

  'InstaEmbellish': {
    'A': 'A♥', 'B': 'B♥', 'C': 'C♥', 'D': 'D♥', 'E': 'E♥', 'F': 'F♥', 'G': 'G♥', 'H': 'H♥', 'I': 'I♥', 'J': 'J♥', 'K': 'K♥', 'L': 'L♥', 'M': 'M♥', 'N': 'N♥', 'O': 'O♥', 'P': 'P♥', 'Q': 'Q♥', 'R': 'R♥', 'S': 'S♥', 'T': 'T♥', 'U': 'U♥', 'V': 'V♥', 'W': 'W♥', 'X': 'X♥', 'Y': 'Y♥', 'Z': 'Z♥',
    'a': 'a♥', 'b': 'b♥', 'c': 'c♥', 'd': 'd♥', 'e': 'e♥', 'f': 'f♥', 'g': 'g♥', 'h': 'h♥', 'i': 'i♥', 'j': 'j♥', 'k': 'k♥', 'l': 'l♥', 'm': 'm♥', 'n': 'n♥', 'o': 'o♥', 'p': 'p♥', 'q': 'q♥', 'r': 'r♥', 's': 's♥', 't': 't♥', 'u': 'u♥', 'v': 'v♥', 'w': 'w♥', 'x': 'x♥', 'y': 'y♥', 'z': 'z♥'
  },

  'FontFlutter': {
    'A': '[A̲̅]', 'B': '[B̲̅]', 'C': '[C̲̅]', 'D': '[D̲̅]', 'E': '[E̲̅]', 'F': '[F̲̅]', 'G': '[G̲̅]', 'H': '[H̲̅]', 'I': '[I̲̅]', 'J': '[J̲̅]', 'K': '[K̲̅]', 'L': '[L̲̅]', 'M': '[M̲̅]', 'N': '[N̲̅]', 'O': '[O̲̅]', 'P': '[P̲̅]', 'Q': '[Q̲̅]', 'R': '[R̲̅]', 'S': '[S̲̅]', 'T': '[T̲̅]', 'U': '[U̲̅]', 'V': '[V̲̅]', 'W': '[W̲̅]', 'X': '[X̲̅]', 'Y': '[Y̲̅]', 'Z': '[Z̲̅]',
    'a': '[a̲̅]', 'b': '[b̲̅]', 'c': '[c̲̅]', 'd': '[d̲̅]', 'e': '[e̲̅]', 'f': '[f̲̅]', 'g': '[g̲̅]', 'h': '[h̲̅]', 'i': '[i̲̅]', 'j': '[j̲̅]', 'k': '[k̲̅]', 'l': '[l̲̅]', 'm': '[m̲̅]', 'n': '[n̲̅]', 'o': '[o̲̅]', 'p': '[p̲̅]', 'q': '[q̲̅]', 'r': '[r̲̅]', 's': '[s̲̅]', 't': '[t̲̅]', 'u': '[u̲̅]', 'v': '[v̲̅]', 'w': '[w̲̅]', 'x': '[x̲̅]', 'y': '[y̲̅]', 'z': '[z̲̅]'
  },

  'Magical Celestial Font': {
    'A': 'Ꭺ', 'B': '𝔹', 'C': 'ℂ', 'D': '𝔻', 'E': 'ε', 'F': 'ғ', 'G': '𝕘', 'H': 'ℍ', 'I': '𝕀', 'J': '𝕁', 'K': '𝕂', 'L': '𝕃', 'M': '𝕄', 'N': 'Ň', 'O': 'Ⓞ', 'P': '𝔻', 'Q': '𝕼', 'R': 'г', 'S': '𝕊', 'T': '丅', 'U': '𝕌', 'V': '𝕍', 'W': '𝕎', 'X': '𝕏', 'Y': '𝕐', 'Z': '𝕫',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': 'ε', 'f': '𝒻', 'g': '𝕘', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': 'г', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },

  'Starlit Dreams': {
    'A': 'ꓝ', 'B': '𝝤', 'C': '𝝢', 'D': '𝝩', 'E': 'Ᏽ', 'F': '𝝚', 'G': '𝝤', 'H': '𝝚', 'I': '𝝩', 'J': '𝝤', 'K': '𝝚', 'L': '𝝢', 'M': '𝝩', 'N': '𝝤', 'O': '𝝩', 'P': 'Ɍ', 'Q': '𝝖', 'R': 'Ɍ', 'S': '𝝩', 'T': '𝝤', 'U': '𝝢', 'V': '𝝚', 'W': '𝝤', 'X': '𝝩', 'Y': 'Ɍ', 'Z': '𝝖',
    'a': 'ꓝ', 'b': '𝝤', 'c': '𝝢', 'd': '𝝩', 'e': 'Ᏽ', 'f': '𝝚', 'g': '𝝤', 'h': '𝝚', 'i': '𝝩', 'j': '𝝤', 'k': '𝝚', 'l': '𝝢', 'm': '𝝩', 'n': '𝝤', 'o': '𝝩', 'p': 'Ɍ', 'q': '𝝖', 'r': 'Ɍ', 's': '𝝩', 't': '𝝤', 'u': '𝝢', 'v': '𝝚', 'w': '𝝤', 'x': '𝝩', 'y': 'Ɍ', 'z': '𝝖'
  },

  'Celestial Harmony': {
    'A': '𝐴', 'B': '𝐵', 'C': '𝐶', 'D': '𝐷', 'E': '𝐸', 'F': '𝔽', 'G': 'Ğ', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝐾', 'L': '𝐿', 'M': '𝑀', 'N': '𝓝', 'O': 'Ⓞ', 'P': '𝒫', 'Q': '𝒬', 'R': 'Ⓡ', 'S': '𝒮', 'T': 'ţ', 'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': 'ᵉ', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },

  'Moonbeam Magic': {
    'A': 'ꓝ', 'B': '𝝤', 'C': '𝝢', 'D': '𝝩', 'E': 'Ᏽ', 'F': '𝝚', 'G': '𝝤', 'H': '𝝚', 'I': '𝝩', 'J': '𝝤', 'K': '𝝚', 'L': '𝝢', 'M': '𝝩', 'N': '𝝤', 'O': '𝝩', 'P': 'Ɍ', 'Q': '𝝖', 'R': 'Ɍ', 'S': '𝝩', 'T': '𝝤', 'U': '𝝢', 'V': '𝝚', 'W': '𝝤', 'X': '𝝩', 'Y': 'Ɍ', 'Z': '𝝖',
    'a': 'ꓝ', 'b': '𝝤', 'c': '𝝢', 'd': '𝝩', 'e': 'Ᏽ', 'f': '𝝚', 'g': '𝝤', 'h': '𝝚', 'i': '𝝩', 'j': '𝝤', 'k': '𝝚', 'l': '𝝢', 'm': '𝝩', 'n': '𝝤', 'o': '𝝩', 'p': 'Ɍ', 'q': '𝝖', 'r': 'Ɍ', 's': '𝝩', 't': '𝝤', 'u': '𝝢', 'v': '𝝚', 'w': '𝝤', 'x': '𝝩', 'y': 'Ɍ', 'z': '𝝖'
  },

  'Mystic Melody': {
    'A': 'α', 'B': 'в', 'C': 'с', 'D': 'ԁ', 'E': 'є', 'F': 'ƒ', 'G': 'g', 'H': 'н', 'I': 'ι', 'J': 'נ', 'K': 'к', 'L': 'ℓ', 'M': 'м', 'N': 'η', 'O': 'σ', 'P': 'ρ', 'Q': 'q', 'R': 'я', 'S': 'ѕ', 'T': 'т', 'U': 'υ', 'V': 'ν', 'W': 'ω', 'X': 'χ', 'Y': 'у', 'Z': 'z',
    'a': 'α', 'b': 'в', 'c': 'с', 'd': 'ԁ', 'e': 'є', 'f': 'ƒ', 'g': 'g', 'h': 'н', 'i': 'ι', 'j': 'נ', 'k': 'к', 'l': 'ℓ', 'm': 'м', 'n': 'η', 'o': 'σ', 'p': 'ρ', 'q': 'q', 'r': 'я', 's': 'ѕ', 't': 'т', 'u': 'υ', 'v': 'ν', 'w': 'ω', 'x': 'χ', 'y': 'у', 'z': 'z'
  },

  'Cosmic Wonder': {
    'A': 'ꓝ', 'B': '𝝤', 'C': '𝝢', 'D': '𝝩', 'E': 'Ᏽ', 'F': '𝝚', 'G': '𝝤', 'H': '𝝚', 'I': '𝝩', 'J': '𝝤', 'K': '𝝚', 'L': '𝝢', 'M': '𝝩', 'N': '𝝤', 'O': '𝝩', 'P': 'Ɍ', 'Q': '𝝖', 'R': 'Ɍ', 'S': '𝝩', 'T': '𝝤', 'U': '𝝢', 'V': '𝝚', 'W': '𝝤', 'X': '𝝩', 'Y': 'Ɍ', 'Z': '𝝖',
    'a': 'ꓝ', 'b': '𝝤', 'c': '𝝢', 'd': '𝝩', 'e': 'Ᏽ', 'f': '𝝚', 'g': '𝝤', 'h': '𝝚', 'i': '𝝩', 'j': '𝝤', 'k': '𝝚', 'l': '𝝢', 'm': '𝝩', 'n': '𝝤', 'o': '𝝩', 'p': 'Ɍ', 'q': '𝝖', 'r': 'Ɍ', 's': '𝝩', 't': '𝝤', 'u': '𝝢', 'v': '𝝚', 'w': '𝝤', 'x': '𝝩', 'y': 'Ɍ', 'z': '𝝖'
  },

  'Twilight Magic': {
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚', 'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡', 'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨', 'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴', 'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻', 'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂', 'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇'
  },

  'Divine Light': {
    'A': 'Ꭺ', 'B': 'Ᏼ', 'C': 'Ꮯ', 'D': 'Ꭰ', 'E': 'Ꭼ', 'F': 'Ꭶ', 'G': 'Ꮆ', 'H': 'Ꮋ', 'I': 'Ꭵ', 'J': 'Ꭻ', 'K': 'Ꮶ', 'L': 'Ꭷ', 'M': 'Ꮇ', 'N': 'Ꮑ', 'O': 'Ꭷ', 'P': 'Ꮲ', 'Q': 'Ᏺ', 'R': 'Ꮢ', 'S': 'Ꮪ', 'T': 'Ꮦ', 'U': 'Ꮜ', 'V': 'Ꮩ', 'W': 'Ꮗ', 'X': 'Ꮿ', 'Y': 'Ꭹ', 'Z': 'Ꮓ',
    'a': 'Ꭶ', 'b': 'Ᏼ', 'c': 'Ꮯ', 'd': 'Ꭰ', 'e': 'Ꭼ', 'f': 'Ꭶ', 'g': 'Ꮆ', 'h': 'Ꮋ', 'i': 'Ꭵ', 'j': 'Ꭻ', 'k': 'Ꮶ', 'l': 'Ꭷ', 'm': 'Ꮇ', 'n': 'Ꮑ', 'o': 'Ꭷ', 'p': 'Ꮲ', 'q': 'Ᏺ', 'r': 'Ꮢ', 's': 'Ꮪ', 't': 'Ꮦ', 'u': 'Ꮜ', 'v': 'Ꮩ', 'w': 'Ꮗ', 'x': 'Ꮿ', 'y': 'Ꭹ', 'z': 'Ꮓ'
  },

  'Moonlit Melody': {
    'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z'
  },

  'Mystic Mirage': {
    'A': '𝒜', 'B': '𝐵', 'C': '𝐶', 'D': '𝐷', 'E': '𝐸', 'F': '𝓕', 'G': '𝒢', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝐾', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩', 'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': '𝓇', 'S': '𝒮', 'T': '𝓉', 'U': '𝒲', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },

  'Celestial Chime': {
    'A': '𝒜', 'B': '𝐵', 'C': '𝐶', 'D': '𝐷', 'E': '𝐸', 'F': '𝒻', 'G': '𝒢', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝐾', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩', 'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': '𝒭', 'S': '𝒮', 'T': '𝒯', 'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝒼', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝔯', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },

  'Mystic Moonlight': {
    'A': 'Ǎ', 'B': 'Ᏼ', 'C': 'Ꮯ', 'D': 'Ꭰ', 'E': 'ɛ', 'F': 'ʄ', 'G': 'ɢ', 'H': 'ɥ', 'I': 'ı', 'J': 'ʝ', 'K': 'Ҝ', 'L': 'ʟ', 'M': 'ʍ', 'N': 'ռ', 'O': 'օ', 'P': 'ρ', 'Q': 'Ԛ', 'R': 'ʀ', 'S': 'ʂ', 'T': 'ȶ', 'U': 'ʉ', 'V': 'ʋ', 'W': 'ʍ', 'X': 'χ', 'Y': 'ʏ', 'Z': 'Ꮓ',
    'a': 'ʍ', 'b': 'Ᏼ', 'c': 'Ꮯ', 'd': 'Ꭰ', 'e': 'ɛ', 'f': 'ʄ', 'g': 'ɢ', 'h': 'ɥ', 'i': 'ı', 'j': 'ʝ', 'k': 'Ҝ', 'l': 'ʟ', 'm': 'ʍ', 'n': 'ռ', 'o': 'օ', 'p': 'ρ', 'q': 'Ԛ', 'r': 'ʀ', 's': 'ʂ', 't': 'ȶ', 'u': 'ʉ', 'v': 'ʋ', 'w': 'ʍ', 'x': 'χ', 'y': 'ʏ', 'z': 'Ꮓ'
  },

  'Celestial Citadel': {
    'A': 'Ա', 'B': 'Բ', 'C': 'Ͳ', 'D': 'ድ', 'E': 'ē', 'F': 'f', 'G': 'ግ', 'H': 'ህ', 'I': 'ΐ', 'J': 'ϳ', 'K': 'Ϗ', 'L': 'Լ', 'M': 'ϻ', 'N': 'ṋ', 'O': '໐', 'P': 'ρ', 'Q': 'Ϗ', 'R': 'r', 'S': 'Ꞩ', 'T': 't', 'U': 'ц', 'V': 'ν', 'W': 'ω', 'X': 'χ', 'Y': 'ʏ', 'Z': 'Ζ',
    'a': 'α', 'b': 'в', 'c': 'ς', 'd': 'ɗ', 'e': 'е', 'f': 'f', 'g': 'ɢ', 'h': 'һ', 'i': 'ι', 'j': 'ϳ', 'k': 'к', 'l': 'ℓ', 'm': 'м', 'n': 'и', 'o': 'ຖ', 'p': 'ρ', 'q': 'ԛ', 'r': 'r', 's': 'ʂ', 't': 'т', 'u': 'υ', 'v': 'ν', 'w': 'ω', 'x': 'χ', 'y': 'ʏ', 'z': 'ζ'
  },

  'DreamType': {
    'A': 'ᗩ', 'B': 'ᗷ', 'C': 'ᑕ', 'D': 'ᗪ', 'E': 'ᗴ', 'F': 'ᖴ', 'G': 'Ꮐ', 'H': 'ᗧ', 'I': 'Ꭵ', 'J': 'Ꮰ', 'K': 'Ꮶ', 'L': 'Ꮭ', 'M': 'ᗰ', 'N': 'ᓗ', 'O': 'ᗧ', 'P': 'ᖘ', 'Q': 'ᑫ', 'R': 'ᖇ', 'S': 'ᔕ', 'T': 'ᖶ', 'U': 'ᓲ', 'V': 'Ꮩ', 'W': 'ᗯ', 'X': '᙭', 'Y': 'ᖻ', 'Z': 'Ꮓ',
    'a': 'ᓮ', 'b': 'Ᏼ', 'c': 'ᓍ', 'd': 'ᑫ', 'e': 'ᓂ', 'f': 'Ꮲ', 'g': 'Ꮆ', 'h': 'Ᏺ', 'i': 'Ꭵ', 'j': 'Ꮷ', 'k': 'Ꮶ', 'l': 'ᓚ', 'm': 'ᗰ', 'n': 'ᓗ', 'o': 'ᗧ', 'p': 'Ꭷ', 'q': 'ᑳ', 'r': 'ᖇ', 's': 'ᔕ', 't': 'ᖶ', 'u': 'ᓲ', 'v': 'Ꮩ', 'w': 'ᗯ', 'x': '᙭', 'y': 'ᖻ', 'z': 'Ꮓ'
  },

  'MagicalScript': {
    'A': 'ą', 'B': 'ʙ', 'C': 'Ϗ', 'D': 'ᗪ', 'E': 'ҽ', 'F': 'Ƒ', 'G': 'Ɠ', 'H': 'н', 'I': 'ι', 'J': 'ϳ', 'K': 'Ϗ', 'L': 'ʟ', 'M': 'м', 'N': 'ղ', 'O': 'օ', 'P': 'ρ', 'Q': 'Ϗ', 'R': 'ɾ', 'S': 'Ꞩ', 'T': '†', 'U': 'ų', 'V': 'ν', 'W': 'ω', 'X': 'χ', 'Y': 'ʏ', 'Z': 'Ƶ',
    'a': 'ą', 'b': 'в', 'c': 'ϲ', 'd': 'ԁ', 'e': 'ҽ', 'f': 'ƒ', 'g': 'ց', 'h': 'հ', 'i': 'ι', 'j': 'ϳ', 'k': 'κ', 'l': 'ⅼ', 'm': 'м', 'n': 'ղ', 'o': 'օ', 'p': 'ρ', 'q': 'զ', 'r': 'ɾ', 's': 'ʂ', 't': 'т', 'u': 'ų', 'v': 'ν', 'w': 'ω', 'x': 'χ', 'y': 'ʏ', 'z': 'ʐ'
  },

  'MoonlitLetters': {
    'A': 'Ǎ', 'B': 'Ᏼ', 'C': 'Ꮯ', 'D': 'Ꭰ', 'E': 'ɛ', 'F': 'ʄ', 'G': 'ɢ', 'H': 'ɥ', 'I': 'ι', 'J': 'ϳ', 'K': 'қ', 'L': 'ʟ', 'M': 'м', 'N': 'ռ', 'O': 'օ', 'P': 'ρ', 'Q': 'Ϗ', 'R': 'ʀ', 'S': 'Ꞩ', 'T': 'ȶ', 'U': 'ʉ', 'V': 'ν', 'W': 'ω', 'X': 'χ', 'Y': 'ʏ', 'Z': 'Ƶ',
    'a': 'α', 'b': 'в', 'c': 'ϲ', 'd': 'ɗ', 'e': 'ɛ', 'f': 'ʄ', 'g': 'ɢ', 'h': 'һ', 'i': 'ι', 'j': 'ϳ', 'k': 'қ', 'l': 'ⅼ', 'm': 'м', 'n': 'ռ', 'o': 'օ', 'p': 'ρ', 'q': 'զ', 'r': 'ʀ', 's': 'ʂ', 't': 'ȶ', 'u': 'ʉ', 'v': 'ν', 'w': 'ω', 'x': 'χ', 'y': 'ʏ', 'z': 'ʐ'
  },

  'MysticSans': {
    'A': 'ɐ', 'B': 'ᗷ', 'C': 'Ↄ', 'D': 'ᗪ', 'E': 'ǝ', 'F': 'Ⅎ', 'G': 'Ꮆ', 'H': 'Һ', 'I': 'ᴉ', 'J': 'ϳ', 'K': 'Ԛ', 'L': 'Ɬ', 'M': 'Ꮇ', 'N': 'u', 'O': 'o', 'P': 'ԫ', 'Q': 'Ԛ', 'R': 'ɹ', 'S': 'ʃ', 'T': 'ʇ', 'U': 'u', 'V': 'ʋ', 'W': 'ʍ', 'X': '×', 'Y': 'ʎ', 'Z': 'Ƨ',
    'a': 'ɐ', 'b': 'ʍ', 'c': 'Ↄ', 'd': 'ᵭ', 'e': 'ǝ', 'f': 'Ⅎ', 'g': 'ƃ', 'h': 'ɥ', 'i': 'ᴉ', 'j': 'ϳ', 'k': 'ƙ', 'l': 'ʃ', 'm': 'ᵯ', 'n': 'u', 'o': 'o', 'p': 'ρ', 'q': 'Ԛ', 'r': 'ɹ', 's': 'ʃ', 't': 'ʇ', 'u': 'u', 'v': 'ʋ', 'w': 'ʍ', 'x': '×', 'y': 'ʎ', 'z': 'ƨ'
  },

  'CelestialScript': {
    'A': 'α', 'B': 'в', 'C': 'ϲ', 'D': 'ɗ', 'E': 'є', 'F': 'ƒ', 'G': 'ɢ', 'H': 'н', 'I': 'ι', 'J': 'ϳ', 'K': 'к', 'L': 'ℓ', 'M': 'м', 'N': 'η', 'O': 'σ', 'P': 'ρ', 'Q': 'Ϗ', 'R': 'я', 'S': 'ѕ', 'T': 'т', 'U': 'υ', 'V': 'ν', 'W': 'ω', 'X': 'χ', 'Y': 'ʏ', 'Z': 'Ƶ',
    'a': 'α', 'b': 'в', 'c': 'ϲ', 'd': 'ɗ', 'e': 'є', 'f': 'ƒ', 'g': 'ɢ', 'h': 'һ', 'i': 'ι', 'j': 'ϳ', 'k': 'к', 'l': 'ℓ', 'm': 'м', 'n': 'η', 'o': 'σ', 'p': 'ρ', 'q': 'զ', 'r': 'я', 's': 'ѕ', 't': 'т', 'u': 'υ', 'v': 'ν', 'w': 'ω', 'x': 'χ', 'y': 'ʏ', 'z': 'ζ'
  },
  'StarlightSans': {
    'A': '𐤠', 'B': '𐤵', 'C': '𐤲', 'D': '𐤳', 'E': '𐤴', 'F': 'Ƒ', 'G': 'Ɠ', 'H': '𐤵', 'I': '𐤲', 'J': '𐤱', 'K': '𐤰', 'L': '𐤯', 'M': '𐤭', 'N': 'Ɲ', 'O': 'Θ', 'P': '𐤧', 'Q': '𐤦', 'R': 'Ɽ', 'S': '𐤧', 'T': 'Ƭ', 'U': '𐤦', 'V': '𐤥', 'W': '𐤣', 'X': '𐤡', 'Y': '𐤠', 'Z': '𐤟',
    'a': '𐤣', 'b': '𐤡', 'c': '𐤠', 'd': '𐤩', 'e': '𐤧', 'f': '𐤦', 'g': '𐤦', 'h': '𐤦', 'i': '𐤤', 'j': '𐤣', 'k': '𐤥', 'l': '𐤥', 'm': '𐤠', 'n': '𐤥', 'o': '𐤥', 'p': '𐤧', 'q': '𐤦', 'r': 'Ɽ', 's': '𐤧', 't': 'Ƭ', 'u': '𐤦', 'v': '𐤧', 'w': '𐤥', 'x': '𐤧', 'y': '𐤣', 'z': '𐤥'
  },

  'FloralCaps': {
    'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z'
  },

  'Blossom': {
    'A': '𝐴', 'B': '𝐵', 'C': '𝐶', 'D': '𝐷', 'E': '𝐸', 'F': 'ℱ', 'G': '𝒢', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝒦', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩', 'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': '𝑅', 'S': '𝒮', 'T': '𝓉', 'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': 'ℯ', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },


  'Daisy Delight': {
    'A': '𝐴', 'B': '𝐵', 'C': '𝐶', 'D': '𝐷', 'E': '𝑒', 'F': '𝐹', 'G': '𝒢', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝒦', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩', 'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': '𝑅', 'S': '𝒮', 'T': '𝒯', 'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },

  'Rosewood Font': {
    'A': '𝐴', 'B': '𝐵', 'C': '𝐶', 'D': '𝐷', 'E': '𝑒', 'F': '𝐹', 'G': '𝐺', 'H': '𝐻', 'I': '𝐼', 'J': '𝐽', 'K': '𝐾', 'L': '𝐿', 'M': '𝑀', 'N': '𝑁', 'O': '𝒪', 'P': '𝑷', 'Q': '𝒬', 'R': '𝑹', 'S': '𝒮', 'T': '𝑻', 'U': '𝑈', 'V': '𝑉', 'W': '𝑊', 'X': '𝑋', 'Y': '𝑌', 'Z': '𝑍',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },

  'Daffodil Doodles': {
    'A': '𝒜', 'B': '𝐵', 'C': '𝒞', 'D': '𝒟', 'E': '𝑒', 'F': '𝐹', 'G': '𝒢', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝒦', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩', 'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': '𝑅', 'S': '𝒮', 'T': '𝒯', 'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },

  'Sunflower Script': {
    'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z'
  },

  'Tulip Type': {
    'A': '𐤠', 'B': '', 'C': '', 'D': '', 'E': 'Ɽ', 'F': 'Ƒ', 'G': 'Ɠ', 'H': '', 'I': '', 'J': '', 'K': '', 'L': '', 'M': '', 'N': 'Ɲ', 'O': 'Θ', 'P': '', 'Q': '', 'R': 'Ɽ', 'S': '', 'T': 'Ƭ', 'U': '', 'V': '', 'W': '', 'X': '', 'Y': '', 'Z': '',
    'a': '', 'b': '', 'c': '', 'd': '', 'e': '', 'f': '', 'g': '', 'h': '', 'i': '', 'j': '', 'k': '', 'l': '', 'm': '', 'n': '', 'o': '', 'p': '', 'q': '', 'r': '', 's': '', 't': '', 'u': '', 'v': '', 'w': '', 'x': '', 'y': '', 'z': ''
  },


  'Love Lettering': {
    'A': '𝒜', 'B': '𝐵', 'C': '𝒞', 'D': '𝐷', 'E': '𝕖', 'F': '𝔽', 'G': '𝔾', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝒦', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩', 'O': '𝒪', 'P': '𝒫', 'Q': '𝒬', 'R': '𝕣', 'S': '𝒮', 'T': '𝕥', 'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },

  'Graceful Script': {
    'A': '𐤠', 'B': '', 'C': '', 'D': '', 'E': 'Ƹ', 'F': 'Ƒ', 'G': 'Ɠ', 'H': '', 'I': '', 'J': '', 'K': '', 'L': '', 'M': '', 'N': 'Ɲ', 'O': 'Θ', 'P': '', 'Q': '', 'R': 'Ɽ', 'S': '', 'T': 'Ƭ', 'U': '', 'V': '', 'W': '', 'X': '', 'Y': '', 'Z': '',
    'a': '', 'b': '', 'c': '', 'd': '', 'e': '', 'f': '', 'g': '', 'h': '', 'i': '', 'j': '', 'k': '', 'l': '', 'm': '', 'n': '', 'o': '', 'p': '', 'q': '', 'r': '', 's': '', 't': '', 'u': '', 'v': '', 'w': '', 'x': '', 'y': '', 'z': ''
  },

  'Vow Script': {
    'A': '', 'B': '', 'C': '', 'D': '', 'E': 'ｅ', 'F': '', 'G': '', 'H': '', 'I': '', 'J': '', 'K': '', 'L': '', 'M': '', 'N': '', 'O': '๏', 'P': '', 'Q': '', 'R': '𝔯', 'S': '', 'T': 'Ｔ', 'U': 'ù', 'V': '', 'W': '', 'X': '𝓍', 'Y': '𝓎', 'Z': '',
    'a': '', 'b': '', 'c': '', 'd': '', 'e': '𝑒', 'f': '', 'g': '', 'h': '', 'i': '', 'j': '', 'k': '', 'l': '', 'm': '', 'n': '', 'o': '', 'p': '', 'q': '', 'r': '𝓇', 's': '', 't': '𝓽', 'u': 'ù', 'v': '', 'w': '', 'x': '𝓍', 'y': '𝓎', 'z': ''
  },


  'Love Serif': {
    'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z'
  },

  'Springtime': {
    'A': '', 'B': '', 'C': '', 'D': '', 'E': 'ｅ', 'F': '', 'G': '', 'H': '', 'I': '', 'J': '', 'K': '', 'L': '', 'M': '', 'N': '', 'O': '🌸', 'P': '', 'Q': '', 'R': '𝔯', 'S': '', 'T': 'Ｔ', 'U': 'ù', 'V': '', 'W': '', 'X': '𝓍', 'Y': '𝓎', 'Z': '',
    'a': '', 'b': '', 'c': '', 'd': '', 'e': '𝑒', 'f': '', 'g': '', 'h': '', 'i': '', 'j': '', 'k': '', 'l': '', 'm': '', 'n': '', 'o': '๏', 'p': '', 'q': '', 'r': '𝓇', 's': '', 't': '𝓽', 'u': 'ù', 'v': '', 'w': '', 'x': '𝓍', 'y': '𝓎', 'z': ''
  },

  'Rendition Serif': {
    'A': '', 'B': '', 'C': '', 'D': '', 'E': '𝑒', 'F': '', 'G': '', 'H': '', 'I': '', 'J': '', 'K': '', 'L': '', 'M': '', 'N': '', 'O': '', 'P': '', 'Q': '', 'R': '𝓇', 'S': '', 'T': '𝒯', 'U': '𝓊', 'V': '', 'W': '', 'X': '𝓍', 'Y': '𝒴', 'Z': '',
    'a': '', 'b': '', 'c': '', 'd': '', 'e': '𝑒', 'f': '', 'g': '', 'h': '', 'i': '', 'j': '', 'k': '', 'l': '', 'm': '', 'n': '', 'o': '', 'p': '', 'q': '', 'r': '𝓇', 's': '', 't': '𝓉', 'u': '𝓊', 'v': '', 'w': '', 'x': '𝓍', 'y': '', 'z': ''
  },


  'DigiStamps': {
    'A': 'α', 'B': '', 'C': '', 'D': '', 'E': 'ε', 'F': 'ғ', 'G': 'ɢ', 'H': '', 'I': '', 'J': '', 'K': '', 'L': '', 'M': '', 'N': 'ɴ', 'O': 'o', 'P': '', 'Q': '', 'R': 'r', 'S': '', 'T': 'т', 'U': '', 'V': '', 'W': '', 'X': '', 'Y': '', 'Z': '',
    'a': 'α', 'b': '', 'c': '', 'd': '', 'e': 'ε', 'f': 'ғ', 'g': 'ɢ', 'h': '', 'i': '', 'j': '', 'k': '', 'l': '', 'm': '', 'n': 'ɴ', 'o': 'o', 'p': '', 'q': '', 'r': 'r', 's': '', 't': 'т', 'u': '', 'v': '', 'w': '', 'x': '', 'y': '', 'z': ''
  },

  'Sweet Flower': {
    'A': '𝒜', 'B': '𝐵', 'C': '𝒞', 'D': '𝒟', 'E': '𝐸', 'F': '𝐹', 'G': '𝒢', 'H': '𝐻', 'I': '𝐼', 'J': '𝒥', 'K': '𝒦', 'L': '𝐿', 'M': '𝑀', 'N': '𝒩', 'O': '🌸', 'P': '𝒫', 'Q': '𝒬', 'R': '𝑅', 'S': '𝒮', 'T': '𝒯', 'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '🌸', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝓉', 'u': '𝓊', 'v': '𝓋', 'w': '𝓌', 'x': '𝓍', 'y': '𝓎', 'z': '𝓏'
  },


  'Petal Prose': {
    'A': '', 'B': '', 'C': '', 'D': '', 'E': '', 'F': 'Ƒ', 'G': 'Ɠ', 'H': '', 'I': '', 'J': '', 'K': '', 'L': '', 'M': '', 'N': 'Ɲ', 'O': 'Θ', 'P': '', 'Q': '', 'R': 'Ɽ', 'S': '', 'T': 'Ƭ', 'U': '', 'V': '', 'W': '', 'X': '', 'Y': '', 'Z': '',
    'a': '', 'b': '', 'c': '', 'd': '', 'e': '', 'f': '', 'g': '', 'h': '', 'i': '', 'j': '', 'k': '', 'l': '', 'm': '', 'n': '', 'o': '', 'p': '', 'q': '', 'r': '', 's': '', 't': '', 'u': '', 'v': '', 'w': '', 'x': '', 'y': '', 'z': ''
  },


  'Daisy Dream': {
    'A': 'A', 'B': 'B', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'F', 'G': 'G', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'N', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'R', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p', 'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z'
  },


  'Blooming Beauty': {
    'A': 'ᗩ', 'B': 'ᖴ', 'C': 'C', 'D': 'D', 'E': 'E', 'F': 'ᖴ', 'G': 'GE', 'H': 'H', 'I': 'I', 'J': 'J', 'K': 'K', 'L': 'L', 'M': 'M', 'N': 'ᑎ', 'O': 'O', 'P': 'P', 'Q': 'Q', 'R': 'ᖇ', 'S': 'S', 'T': 'T', 'U': 'U', 'V': 'V', 'W': 'W', 'X': 'X', 'Y': 'Y', 'Z': 'Z',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'E', 'f': 'f', 'g': 'g', 'h': 'h', 'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'ᑎ', 'o': 'O', 'p': 'p', 'q': 'q', 'r': 'ᖇ', 's': 's', 't': 'T', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x', 'y': 'y', 'z': 'z'
  },


  'Poppy Pages': {
    'A': '𝓐', 'B': '𝓑', 'C': '𝓒', 'D': '𝓓', 'E': '𝓔', 'F': '𝓕', 'G': '𝓖', 'H': '𝓗', 'I': '𝓘', 'J': '𝓙', 'K': '𝓚', 'L': '𝓛', 'M': '𝓜', 'N': '𝓝', 'O': '𝓞', 'P': '𝓟', 'Q': '𝓠', 'R': '𝓡', 'S': '𝓢', 'T': '𝓣', 'U': '𝓤', 'V': '𝓥', 'W': '𝓦', 'X': '𝓧', 'Y': '𝓨', 'Z': '𝓩',
    'a': '𝓪', 'b': '𝓫', 'c': '𝓬', 'd': '𝓭', 'e': '𝓮', 'f': '𝓯', 'g': '𝓰', 'h': '𝓱', 'i': '𝓲', 'j': '𝓳', 'k': '𝓴', 'l': '𝓵', 'm': '𝓶', 'n': '𝓷', 'o': '𝓸', 'p': '𝓹', 'q': '𝓺', 'r': '𝓻', 's': '𝓼', 't': '𝓽', 'u': '𝓾', 'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝔂', 'z': '𝔃'
  },

  'Tulip serif': {
    'A': '𝐴', 'B': '𝐵', 'C': '𝐶', 'D': '𝐷', 'E': '𝔼', 'F': '𝓕', 'G': 'Ꮆ', 'H': '𝐻', 'I': '𝐼', 'J': '𝐽', 'K': '𝐾', 'L': '𝐿', 'M': '𝑀', 'N': '𝕹', 'O': 'Ỗ', 'P': '𝒫', 'Q': '𝒬', 'R': 'ᖇ', 'S': '𝒮', 'T': 'ᵗ', 'U': '𝒰', 'V': '𝒱', 'W': '𝒲', 'X': '𝒳', 'Y': '𝒴', 'Z': '𝒵',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': '𝑒', 'f': '𝒻', 'g': '𝒼', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': '𝓇', 's': '𝓈', 't': '𝐭', 'u': '𝓾', 'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝓎', 'z': '𝓏'
  },


  'Hibiscus Handwriting': {
    'A': '𝐀', 'B': '𝐁', 'C': '𝐂', 'D': '𝐃', 'E': '𝔢', 'F': '𝐅', 'G': '𝒢', 'H': '𝐇', 'I': '𝐈', 'J': '𝐉', 'K': '𝐊', 'L': '𝐋', 'M': '𝐌', 'N': 'Ň', 'O': 'Ⓞ', 'P': '𝐏', 'Q': '𝐐', 'R': '𝔯', 'S': '𝐒', 'T': '𝕥', 'U': '𝐔', 'V': '𝐕', 'W': '𝐖', 'X': '𝐗', 'Y': '𝐘', 'Z': '𝐙',
    'a': '𝒶', 'b': '𝒷', 'c': '𝒸', 'd': '𝒹', 'e': 'є', 'f': '𝒻', 'g': '𝑔', 'h': '𝒽', 'i': '𝒾', 'j': '𝒿', 'k': '𝓀', 'l': '𝓁', 'm': '𝓂', 'n': '𝓃', 'o': '𝑜', 'p': '𝓅', 'q': '𝓆', 'r': 'г', 's': '𝓈', 't': 'ᵗ', 'u': '𝓾', 'v': '𝓿', 'w': '𝔀', 'x': '𝔁', 'y': '𝓎', 'z': '𝓏'
  },


  'Lily Lattice': {
    'A': '𝙰', 'B': '𝙱', 'C': '𝙲', 'D': '𝙳', 'E': '𝙴', 'F': '𝙵', 'G': '𝙶', 'H': '𝙷', 'I': '𝙸', 'J': '𝙹', 'K': '𝙺', 'L': '𝙻', 'M': '𝙼', 'N': '𝙽', 'O': '𝙾', 'P': '𝙿', 'Q': '𝚀', 'R': '𝚁', 'S': '𝚂', 'T': '𝚃', 'U': '𝚄', 'V': '𝚅', 'W': '𝚆', 'X': '𝚇', 'Y': '𝚈', 'Z': '𝚉',
    'a': '𝚎', 'b': '𝚎', 'c': '𝚌', 'd': '𝚍', 'e': '𝚎', 'f': '𝚏', 'g': '𝚐', 'h': '𝚑', 'i': '𝚒', 'j': '𝚓', 'k': '𝚔', 'l': '𝚕', 'm': '𝚖', 'n': '𝚗', 'o': '𝚘', 'p': '𝚙', 'q': '𝚚', 'r': '𝚛', 's': '𝚜', 't': '𝚝', 'u': '𝚞', 'v': '𝚟', 'w': '𝚠', 'x': '𝚡', 'y': '𝚢', 'z': '𝚣'
  },


  'Violet Vibes': {
    'A': '𝗔', 'B': '𝗕', 'C': '𝗖', 'D': '𝗗', 'E': '𝗘', 'F': '𝗙', 'G': '𝗚', 'H': '𝗛', 'I': '𝗜', 'J': '𝗝', 'K': '𝗞', 'L': '𝗟', 'M': '𝗠', 'N': '𝗡', 'O': '𝗢', 'P': '𝗣', 'Q': '𝗤', 'R': '𝗥', 'S': '𝗦', 'T': '𝗧', 'U': '𝗨', 'V': '𝗩', 'W': '𝗪', 'X': '𝗫', 'Y': '𝗬', 'Z': '𝗭',
    'a': '𝗮', 'b': '𝗯', 'c': '𝗰', 'd': '𝗱', 'e': '𝗲', 'f': '𝗳', 'g': '𝗴', 'h': '𝗵', 'i': '𝗶', 'j': '𝗷', 'k': '𝗸', 'l': '𝗹', 'm': '𝗺', 'n': '𝗻', 'o': '𝗼', 'p': '𝗽', 'q': '𝗾', 'r': '𝗿', 's': '𝘀', 't': '𝘁', 'u': '𝘂', 'v': '𝘃', 'w': '𝘄', 'x': '𝘅', 'y': '𝘆', 'z': '𝘇'
  },


  'Floral Fancy': {
    'A': '𝐀', 'B': '𝐁', 'C': '𝐂', 'D': '𝐃', 'E': '𝐄', 'F': '𝐅', 'G': '𝐆', 'H': '𝐇', 'I': '𝐈', 'J': '𝐉', 'K': '𝐊', 'L': '𝐋', 'M': '𝐌', 'N': '𝐍', 'O': '𝐎', 'P': '𝐏', 'Q': '𝐐', 'R': '𝐑', 'S': '𝐒', 'T': '𝐓', 'U': '𝐔', 'V': '𝐕', 'W': '𝐖', 'X': '𝐗', 'Y': '𝐘', 'Z': '𝐙',
    'a': '𝐚', 'b': '𝐛', 'c': '𝐜', 'd': '𝐝', 'e': '𝐞', 'f': '𝐟', 'g': '𝐠', 'h': '𝐡', 'i': '𝐢', 'j': '𝐣', 'k': '𝐤', 'l': '𝐥', 'm': '𝐦', 'n': '𝐧', 'o': '𝐨', 'p': '𝐩', 'q': '𝐪', 'r': '𝐫', 's': '𝐬', 't': '𝐭', 'u': '𝐮', 'v': '𝐯', 'w': '𝐰', 'x': '𝐱', 'y': '𝐲', 'z': '𝐳'
  },


  'Bluebell Brush': {
    'A': 'ᴀ', 'B': 'ʙ', 'C': 'ᴄ', 'D': 'ᴅ', 'E': 'ᴇ', 'F': 'ꜰ', 'G': 'ɢ', 'H': 'ʜ', 'I': 'ɪ', 'J': 'ᴊ', 'K': 'ᴋ', 'L': 'ʟ', 'M': 'ᴍ', 'N': 'ɴ', 'O': 'ᴏ', 'P': 'ᴘ', 'Q': 'ǫ', 'R': 'ʀ', 'S': 's', 'T': 'ᴛ', 'U': 'ᴜ', 'V': 'ᴠ', 'W': 'ᴡ', 'X': 'x', 'Y': 'ʏ', 'Z': 'ᴢ',
    'a': 'ᴀ', 'b': 'ʙ', 'c': 'ᴄ', 'd': 'ᴅ', 'e': 'ᴇ', 'f': 'ꜰ', 'g': 'ɢ', 'h': 'ʜ', 'i': 'ɪ', 'j': 'ᴊ', 'k': 'ᴋ', 'l': 'ʟ', 'm': 'ᴍ', 'n': 'ɴ', 'o': 'ᴏ', 'p': 'ᴘ', 'q': 'ǫ', 'r': 'ʀ', 's': 's', 't': 'ᴛ', 'u': 'ᴜ', 'v': 'ᴠ', 'w': 'ᴡ', 'x': 'x', 'y': 'ʏ', 'z': 'ᴢ'
  },

  'Iris Italics': {
    'A': '𝘈', 'B': '𝘉', 'C': '𝘊', 'D': '𝘋', 'E': '𝘌', 'F': '𝘍', 'G': '𝘎', 'H': '𝘏', 'I': '𝘐', 'J': '𝘑', 'K': '𝘒', 'L': '𝘓', 'M': '𝘔', 'N': '𝘕', 'O': '𝘖', 'P': '𝘗', 'Q': '𝘘', 'R': '𝘙', 'S': '𝘚', 'T': '𝘛', 'U': '𝘜', 'V': '𝘝', 'W': '𝘞', 'X': '𝘟', 'Y': '𝘠', 'Z': '𝘡',
    'a': '𝘢', 'b': '𝘣', 'c': '𝘤', 'd': '𝘥', 'e': '𝘦', 'f': '𝘧', 'g': '𝘨', 'h': '𝘩', 'i': '𝘪', 'j': '𝘫', 'k': '𝘬', 'l': '𝘭', 'm': '𝘮', 'n': '𝘯', 'o': '𝘰', 'p': '𝘱', 'q': '𝘲', 'r': '𝘳', 's': '𝘴', 't': '𝘵', 'u': '𝘶', 'v': '𝘷', 'w': '𝘸', 'x': '𝘹', 'y': '𝘺', 'z': '𝘻'
  }

};

const transformText = (text, fontName) => {
  const fontTemplate = fontsMap[fontName];
  if (!fontTemplate) return text;

  const fontMap = fontCharMaps[fontName];
  if (!fontMap) return text;

  const inputText = text.trim() === '' ? fontPlaceholders[fontName] : text;
  const transformedText = inputText.split('').map(char => fontMap[char] || char).join('');

  return fontTemplate.replace('{text}', transformedText);
};

export default transformText;
