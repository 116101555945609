import React from 'react';

const SearchAndCategory = ({ setSearchText, categories, selectedCategory, setSelectedCategory }) => {
  return (
    <div className="sticky top-0 bg-white py-4 shadow-md z-10">
      <div className="container mx-auto flex flex-col items-center px-4 py-4 border rounded-lg" style={{ borderColor: '#6145E1' }}>
        <input
          type="text"
          placeholder="Type or Paste your Text Here..."
          className="w-full md:w-1/2 p-2 border rounded-lg mb-4"
          style={{ borderColor: '#6145E1', color: '#6145E1' }}
          onChange={(e) => setSearchText(e.target.value)}
        />
        <div className="flex flex-wrap justify-center space-x-2">
          <button
            className={`px-4 py-2 rounded mb-2 md:mb-0 ${
              selectedCategory === 'All Categories' ? 'bg-purple-600 text-white' : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'
            }`}
            onClick={() => setSelectedCategory('All Categories')}
          >
            All Categories
          </button>
          {categories.map((category) => (
            <button
              key={category}
              className={`px-4 py-2 rounded mb-2 md:mb-0 ${
                selectedCategory === category ? 'bg-purple-600 text-white' : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'
              }`}
              onClick={() => setSelectedCategory(category)}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchAndCategory;
