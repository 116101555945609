import React from 'react';

const TermsConditions = () => {
  return (
    <section className="container mx-auto my-8 px-4">
      <h2 className="text-3xl font-bold mb-4" style={{ color: '#6145E1' }}>Terms and Conditions</h2>
      <p>Terms and conditions content...</p>
    </section>
  );
};

export default TermsConditions;
