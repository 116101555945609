import React from 'react';

const Header = () => {
  return (
    <header className="bg-white py-4 shadow-md">
      <div className="container mx-auto flex flex-col items-center px-4">
        <div className="text-3xl font-bold text-purple-600">COPY PASTE FONT GENERATOR</div>
        <div className="mt-2 text-lg text-center">
          Transform your text into art with our <span className="font-bold text-purple-600">Fancy Font Generator!</span> Boost⚡Instagram bios, spice up Discord chats, enhance Twitch channels, and craft video status designs👌
        </div>
      </div>
    </header>
  );
};

export default Header;
