import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Header from './components/Header';
import SearchAndCategory from './components/SearchAndCategory';
import FontCard from './components/FontCard';
import BlogPosts from './components/BlogPosts';
import Footer from './components/Footer';
import BlogPage from './components/BlogPage'; // Import the new BlogPage component
import PostPage from './components/PostPage'; // Import the new PostPage component
import PrivacyPolicy from './components/PrivacyPolicy'; // Import PrivacyPolicy component
import TermsConditions from './components/TermsConditions'; // Import TermsConditions component

const fontsData = {
  'Instagram Fonts': ['Fontagram', 'Fontify', 'InstaTexta', 'FontLuxe', 'InstaStyler', 'InstaType','InstaFontopia', 'FontFlair', 'InstaScript', 'FontFusion', 'FontVibe', 'InstaChicFonts', 'InstaPixel', 'FontFiesta', 'InstaCharm', 'FontFable', 'InstaFontology', 'FontMosaic', 'InstaQuill', 'FontSprinkle', 'FontMingle', 'InstaEmbellish', 'FontFlutter'],
  'Magical and Celestial': ['Magical Celestial Font', 'Starlit Dreams', 'Celestial Harmony', 'Moonbeam Magic', 'Mystic Melody', 'Cosmic Wonder', 'Twilight Magic', 'Divine Light', 'Moonlit Melody', 'Mystic Mirage', 'Celestial Chime', 'Mystic Moonlight', 'Celestial Citadel', 'DreamType', 'MagicalScript', 'MoonlitLetters', 'MysticSans', 'CelestialScript', 'MoonlitLetters', 'MagicalScript', 'DreamType', 'StarlightSans'],
  'Floral fonts': ['FloralCaps', 'Blossom', 'Daisy Delight', 'Rosewood Font', 'Daffodil Doodles', 'Sunflower Script', 'Tulip Type', 'Love Lettering', 'Graceful Script', 'Vow Script', 'Love Serif', 'Springtime', 'Rendition Serif', 'DigiStamps', 'Sweet Flower', 'Petal Prose', 'Daisy Dream', 'Blooming Beauty', 'Poppy Pages', 'Tulip serif', 'Hibiscus Handwriting', 'Lily Lattice', 'Violet Vibes', 'Floral Fancy', 'Bluebell Brush', 'Iris Italics']
};

const App = () => {
  const [selectedCategory, setSelectedCategory] = useState('All Categories');
  const [searchText, setSearchText] = useState('');
  const [visibleCount, setVisibleCount] = useState(10);

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setVisibleCount(10); // Reset the visible count when category changes
  };

  const getFontsToDisplay = () => {
    const allFonts = selectedCategory === 'All Categories' ? Object.values(fontsData).flat() : fontsData[selectedCategory];
    return allFonts.slice(0, visibleCount);
  };

  const loadMoreFonts = () => {
    setVisibleCount(prevCount => prevCount + 10);
  };

  const totalFonts = selectedCategory === 'All Categories' ? Object.values(fontsData).flat().length : fontsData[selectedCategory].length;

  return (
    <Router>
      <div className="bg-white min-h-screen flex flex-col">
        <Header />
        <main className="container mx-auto my-8 flex-1 px-4">
          <Routes>
            <Route path="/" element={
              <>
                <SearchAndCategory 
                  setSearchText={setSearchText} 
                  categories={Object.keys(fontsData)} 
                  selectedCategory={selectedCategory} 
                  setSelectedCategory={handleCategoryChange} 
                />
                {getFontsToDisplay().map((fontName, index) => (
                  <FontCard
                    key={`${fontName}-${index}`}
                    fontName={fontName}
                    searchText={searchText}
                  />
                ))}
                {visibleCount < totalFonts && (
                  <button onClick={loadMoreFonts} className="px-4 py-2 mt-4 rounded bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white mx-auto block">
                    Load More
                  </button>
                )}
                <BlogPosts />
                <div className="text-center mt-8">
                  <Link to="/blog" className="px-4 py-2 rounded bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white">
                    View More
                  </Link>
                </div>
              </>
            } />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id" element={<PostPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-conditions" element={<TermsConditions />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
