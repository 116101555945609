import React from 'react';

const PrivacyPolicy = () => {
  return (
    <section className="container mx-auto my-8 px-4">
      <h2 className="text-3xl font-bold mb-4" style={{ color: '#6145E1' }}>Privacy Policy</h2>
      <p>Privacy policy content...</p>
    </section>
  );
};

export default PrivacyPolicy;
