import React, { useState } from 'react';
import transformText from '../transformText';

const FontCard = ({ fontName, searchText }) => {
  const [copied, setCopied] = useState(false);
  const transformedText = transformText(searchText, fontName);

  const handleCopy = () => {
    navigator.clipboard.writeText(transformedText);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000); // Reset copied state after 2 seconds
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md mb-4 border" style={{ borderColor: '#6145E1' }}>
      <h2 className="text-xl font-bold mb-2" style={{ color: '#6145E1' }}>{fontName}</h2>
      <div className="p-2 border rounded-lg flex items-center justify-between mb-4" style={{ borderColor: '#6145E1' }}>
        <input
          type="text"
          value={transformedText}
          readOnly
          className="flex-1 p-2 border-none outline-none"
          style={{ color: '#6145E1' }}
        />
        <button
          onClick={handleCopy}
          className={`p-2 rounded ${
            copied ? 'bg-purple-600 text-white' : 'bg-white text-purple-600 border border-purple-600 hover:bg-purple-600 hover:text-white'
          }`}
        >
          {copied ? 'Copied' : 'Copy'}
        </button>
      </div>
    </div>
  );
};

export default FontCard;
