const postsData = [
  {
    id: 1,
    title: 'Post Title 1',
    description: 'Short description of the blog post...',
    imageUrl: 'https://via.placeholder.com/150',
    filename: 'post1.md',
  },
  {
    id: 2,
    title: 'Post Title 2',
    description: 'Short description of the blog post...',
    imageUrl: 'https://via.placeholder.com/150',
    filename: 'post2.md',
  },
  {
    id: 3,
    title: 'Post Title 3',
    description: 'Short description of the blog post...',
    imageUrl: 'https://via.placeholder.com/150',
    filename: 'post3.md',
  },
];

export default postsData;
