import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import postsData from '../data/postsData'; // Import the posts data

const generateId = (text) => {
  return text.toLowerCase().replace(/\s+/g, '-').replace(/[^\w-]+/g, '');
};

const PostPage = () => {
  const { id } = useParams();
  const [content, setContent] = useState('');
  const [toc, setToc] = useState([]);

  useEffect(() => {
    const post = postsData.find(post => post.filename === `${id}.md`);

    if (post) {
      import(`../posts/${post.filename}`)
        .then(res => fetch(res.default))
        .then(res => res.text())
        .then(text => {
          setContent(text);

          // Generate TOC
          const lines = text.split('\n');
          const toc = lines
            .filter(line => line.startsWith('#'))
            .map(line => {
              const level = line.match(/^#+/)[0].length;
              const text = line.replace(/^#+\s*/, '');
              const id = generateId(text);
              return { level, text, id };
            });
          setToc(toc);
        })
        .catch(err => console.error(err));
    }
  }, [id]);

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <section className="container mx-auto my-8 px-4">
      <div className="flex">
        <div className="w-3/4 pr-8">
          <ReactMarkdown
            children={content}
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            components={{
              h1: ({ node, ...props }) => {
                const id = generateId(props.children ? props.children[0] : '');
                return <h1 id={id} className="text-3xl font-bold mb-4" {...props}>{props.children}</h1>;
              },
              h2: ({ node, ...props }) => {
                const id = generateId(props.children ? props.children[0] : '');
                return <h2 id={id} className="text-2xl font-bold mb-4" {...props}>{props.children}</h2>;
              },
              h3: ({ node, ...props }) => {
                const id = generateId(props.children ? props.children[0] : '');
                return <h3 id={id} className="text-xl font-bold mb-4" {...props}>{props.children}</h3>;
              },
              p: ({ node, ...props }) => <p className="mb-4" {...props}>{props.children}</p>,
              ul: ({ node, ...props }) => <ul className="list-disc list-inside mb-4" {...props}>{props.children}</ul>,
              ol: ({ node, ...props }) => <ol className="list-decimal list-inside mb-4" {...props}>{props.children}</ol>,
              a: ({ node, ...props }) => <a className="text-blue-600 hover:underline" {...props}>{props.children}</a>,
              code: ({ node, ...props }) => <code className="bg-gray-200 rounded px-1" {...props}>{props.children}</code>,
            }}
          />
        </div>
        <aside className="w-1/4 pl-8">
          <h3 className="text-2xl font-bold mb-4 text-purple-600">Table of Contents</h3>
          <ul>
            {toc.map(item => (
              <li key={item.id} className={`ml-${item.level * 2}`}>
                <a href={`#${item.id}`} className="text-purple-600">{item.text}</a>
              </li>
            ))}
          </ul>
        </aside>
      </div>
    </section>
  );
};

export default PostPage;
